import React from "react";
import IMenu from "../interface/IMenu";
import Paths from "../config/Paths";
import {
  HomeOutlined,
  BarsOutlined,
  ColumnWidthOutlined,
  NotificationOutlined
} from "@ant-design/icons";

export default class Menu {
  private _id: number
  private _key: number
  private _description: string
  private _icon: string
  private _order: number
  private _path: string
  private _url: string
  private _submenus: Menu[]

  constructor(menu: IMenu) {
    this._id = menu.id
    this._key = menu.id
    this._description = menu.attributes.description
    this._icon = menu.attributes.icon
    this._order = menu.attributes.order
    this._path = menu.attributes.path
    this._url = menu.attributes.url
    this._submenus = menu.relationships.submenus ? menu.relationships.submenus.map(menu => new Menu(menu)) : []
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get key(): number {
    return this._key;
  }

  set key(value: number) {
    this._key = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  get path(): string {
    return this._path;
  }

  set path(value: string) {
    this._path = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get submenus(): Menu[] {
    return this._submenus;
  }

  set submenus(value: Menu[]) {
    this._submenus = value;
  }

  get iconReactNode() {
    switch (this.path) {
      case Paths.FORMATS:
        return <ColumnWidthOutlined/>
      case Paths.ADVERTISEMENTS:
        return <NotificationOutlined/>
      case Paths.HOME:
        return <HomeOutlined/>
      default:
        return <BarsOutlined/>
    }
  }
}
