import ActionType from "../../types/ActionType";
import {NOTIFICATIONS_SET_DATA, NOTIFICATIONS_SPINNER} from "../constants/notifications";

const initialState: any = {
  total: 0,
  notifications: [],
  spinner: false,
  loaded: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: any = initialState, action: ActionType): any => {
  switch (action.type) {
    case NOTIFICATIONS_SPINNER:
      return {
        ...state,
        spinner: action.payload.spinner
      }
    case NOTIFICATIONS_SET_DATA:
      return {
        ...action.payload
      }
    default:
      return state
  }
}
