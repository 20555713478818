import Paths from "./Paths";

export const Config = {
  END_POINT: 'https://api.anuncios-dinamicos.dev.tolber.io/api/',
  END_POINT_LOGIN: 'https://oauth.dev.tolber.io/auth/realms/TDT/protocol/openid-connect/token',
  END_POINT_USER_INFO: 'https://oauth.dev.tolber.io/auth/realms/TDT/protocol/openid-connect/userinfo',
  LOGIN_CLIENT_ID: 'dynamic-ads',
  EXTERNAL_SERVICES: 'https://api.tdt-resources.dev.tolber.io/api/',
  TDT_COMMONS: 'https://api.tdt-common.demo.tolber.io/api/',
  BASE_URL: 'https://anuncios-dinamicos.dev.tolber.io/',
  PATH_DEFAULT: Paths.ADVERTISEMENTS,
  GOOGLE_MAPS_API_KEY: 'AIzaSyA3yKa84EIH3GsgoP5BmD1Ww641xbMLy9Q',
  PER_PAGE: 15,
  MAP_BOX_STYLE: 'mapbox://styles/mfernandezbe  rtola/ckkytf8rb17db17lkf3ifdp17',
  MAP_BOX_KEY: 'pk.eyJ1IjoibWZlcm5hbmRlemJlcnRvbGEiLCJhIjoiY2tvc3FncDRkMDNoNDJ2bzc1eXFlanl2YSJ9.NtC84ceFiKgkuzFosmDSIQ',
  MAP_BOX_ZOOM: 14,
  TIME_REFRESH_NOTIFICATIONS: 30 * 1000 // segundos * milisegundos
}
