const Messages = {
		CAMPO_REQUERIDO: 'Este campo es requerido',
		EMAIL_INVALIDO: 'Por favor, introduce una dirección de correo electrónico válida',
		INICIAR_SESION: 'Iniciar sesión',
		CONTRASENNA: 'Contraseña',
		REPETIR_CONTRASENNA: 'Repetir contraseña',
		E_MAIL: 'E-mail',
		RECORDAR: 'Recordar',
		OLVIDE_CONTRASENNA: 'Olvidé mi contraseña',
		RECUPERAR_CONTRASENNA: 'Recuperar contraseña',
		CAMBIAR_CONTRASENNA: 'Cambiar contraseña',
		CANCELAR: 'Cancelar',
		GUARDAR: 'Guardar',
		GUARDAR_CAMBIOS: 'Guardar cambios',
		MIS_DATOS: 'Mis datos',
		CERRAR_SESSION: 'Cerrar Sessión',
		VER_TODOS: 'Ver todos',
		INICIO: 'Inicio',
		DASHBOARD: 'Dashboard',
		REPORTE: 'Reporte',
		REPORTES: 'Reportes',
		VER_MAS: 'Ver más',
		DIGITALES: 'Digitales',
		OTROS: 'Otros',
		OTRO: 'Otro',
		CAMPANNAS: 'Campañas',
		CAMPANNA: 'Campaña',
		VENCIMIENTO: 'Vencimiento',
		VENCIMIENTOS: 'Vencimientos',
		VER_CAMPANA: 'Ver Campaña',
		TABLE: {
				filterTitle: 'Menú de filtro',
				filterConfirm: 'Aceptar',
				filterReset: 'Restablecer',
				filterEmptyText: 'Sin filtros',
				selectAll: 'Seleccionar página actual',
				selectInvert: 'Invertir página actual',
				selectionAll: 'Seleccionar todos los datos',
				sortTitle: 'Ordenar',
				expandir: 'Expandir fila',
				collapse: 'Colapsar fila',
				triggerDesc: 'Haga clic para ordenar descendente',
				triggerAsc: 'Haga clic para ordenar ascendente',
				cancelSort: 'Haga clic para cancelar la ordenación',
		},
		DISPOSITIVOS: "Elementos",
		DISPOSITIVO: "Elemento",
		MAS_INFO: "Más info",
		BUSCAR_: "Buscar...",
		BUSCAR: "Buscar",
		FECHAS_DE_CAMPANNAS: "Fechas de campaña",
		DETALLE: "Detalle",
		FECHA_INICIO: "Fecha de inicio",
		FECHA_FIN: "Fecha de fin",
		SELECCIONE: "Seleccione",
		FILTROS: "Filtros",
		TIPO_DE_DISPOSITIVO: "Tipo de elemento",
		TIPOS_DE_ELEMENTOS: "Tipos de elementos",
		FILTRAR: "Filtrar",
		DATE_FORMAT: "DD/MM/YYYY",
		VER_DETALLE: "Ver detalle",
		REVISA_CORREO_POR_INSTRUCCIONES_RECUPERAR_CONTRASENNA: "Revisa tu correo por instrucciones para recuperar tu contraseña",
		LAS_DOS_CONTRASENNAS_NO_COINCIDEN: "Las dos contraseñas no coinciden",
		USUARIOS: "Usuarios",
		USUARIO: "Usuario",
		EDITAR: "Editar",
		ELIMINAR: "Eliminar",
		ERROR: "Error",
		NOTIFICACION: "Notificación",
		INFORMACION: "Información",
		SI: "Si",
		NO: "No",
  LO_SENTIMOS_NO_TIENE_PERMISOS_PARA_VISUALIZAR_LA_PAGINA_QUE_VISITASTE: "Lo sentimos, no tiene permisos para visualizar la página que visistaste.",
  LO_SENTIMOS_LA_PAGINA_QUE_VISITASTE_NO_EXISTE: "Lo sentimos, la página que visitaste no existe.",
  NO_HAY_NUEVAS_NOTIFICACIONES: "No hay nuevas notificaciones",
  CONTRASENNA_CAMBIADA_CORRECTAMENTE: "Contraseña cambiada correctamente",
  ANUNCIOS_DINAMICOS: "Anuncios dinámicos",
  ANUNCIO_DINAMICO: "Anuncio dinámico",
  ACTIVOS: "Activos",
  FINALIZADOS: "Finalizados",
  ID: "Id",
  CUENTA: "Cuenta",
  CUENTAS: "Cuentas",
  NOMBRE_DE_CAMPANNA: "Nombre de campaña",
  NUM_FORMATOS: "# Formatos",
  NUM_IMPRESIONES: "# Impresiones",
  ESTA_SEGURO_QUIERE_ELIMINAR_ESTE_ANUNCIO: "¿Esta seguro que quiere eliminar este anuncio?",
  ESTA_SEGURO_QUIERE_ELIMINAR_ESTE_FORMATO: "¿Esta seguro que quiere eliminar este formato?",
  ESTA_SEGURO_QUIERE_ELIMINAR_ESTE_TEMPLATE: "¿Esta seguro que quiere eliminar este template?",
  FECHA_DE_INICIO_Y_FIN: "Fecha de inicio y fin",
  ANCHO: "Ancho",
  ALTO: "Alto",
  NOMBRE: "Nombre",
  FORMATOS: "Formatos",
  AGREGAR: "Agregar",
  NUEVO_FORMATO: "Nuevo formato",
  EDITAR_FORMATO: "Editar formato",
  NUEVO_ANUNCIO: "Nuevo anuncio",
  EDITAR_TEMPLATE: "Editar template",
  TEMPLATES: "Templates",
  TEMPLATE: "Template",
  NUEVO_TEMPLATE: "Nuevo template",
  RENOMBRAR:'Renombrar',
  DUPLICAR:'Duplicar',
  CONDICIONALES:'Condicionales',
  CRONOGRAMA:'Cronograma',
  FECHA_DE_INICIO_FIN:'Fecha de inicio y fin',
  HORARIO:'Horario',
  TEMPERATURA:'Temperatura',
  CLIMA:'Clima',
  INDICE_DE_RAYOS_UV:'Índice de rayos UV',
  NIVEL_DE_TRAFICO:'Nivel de tráfico',
  SOLO_APLICA_OOH:'Solo aplica a OOH',
  INDICE_DE_CALIDAD_DEL_AIRE:'Índice de calidad del aire',
  ICA:'ICA',
  APLICA_ZONA_DEL_REPRODUCTOR:'Aplica a zona del reproductor',
  APLICA_FECHA_HORA_LOCAL_REPRODUCTOR:'Aplica a fecha y hora local del reproductor',
  SOL:'Sol',
  LLUVIA:'Lluvia',
  TORMENTA:'Tormenta',
  NUBLADO:'Nublado',
  TORNADO:'Tornado',
  BAJO:'Bajo',
  MEDIO:'Medio',
  PUBLICACION:'Publicación',
  GENERAR_URL:'Generar URL',
  DESCARGAR:'Descargar',
  PUBLICADO:'Publicado',
  NO_PUBLICADO:'No publiado',
  EL_FORMATO_HA_SIDO_ACTUALIZADO_CORRECTAMENTE:'El formato ha sido actualizado correctamente',
  EL_FORMATO_HA_SIDO_CREADO_CORRECTAMENTE:'El formato ha sido creado correctamente',
  EL_FORMATO_HA_SIDO_ELIMINADO_CORRECTAMENTE:'El formato ha sido eliminado correctamente',
  SELECCIONE_AL_MENOS_UN_FORMATO:'Seleccione al menos un formato',
  EL_ANUNCIO_HA_SIDO_ACTUALIZADO_CORRECTAMENTE:'El anuncio ha sido actualizado correctamente',
  EL_ANUNCIO_HA_SIDO_CREADO_CORRECTAMENTE:'El anuncio ha sido creado correctamente',
  EL_ANUNCIO_HA_SIDO_ELIMINADO_CORRECTAMENTE:'El anuncio ha sido eliminado correctamente',
  EL_TEMPLATE_HA_SIDO_ACTUALIZADO_CORRECTAMENTE:'El template ha sido actualizado correctamente',
  EL_TEMPLATE_HA_SIDO_CREADO_CORRECTAMENTE:'El template ha sido creado correctamente',
  EL_TEMPLATE_HA_SIDO_ELIMINADO_CORRECTAMENTE:'El template ha sido eliminado correctamente',
}

export default Messages
