import React, {Suspense} from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import Paths from "../config/Paths";
import ErrorBoundaryRoute from './error-boundary-route'
import Loading from "../components/loading/Loading";
import Logout from "../modules/logout/Logout";

const Login = React.lazy(() => import("../modules/login/Login"))
const Home = React.lazy(() => import("../modules/home/Home"))
const RecoverPassword = React.lazy(() => import("../modules/recover-password/RecoverPassword"))
const ConfirmRecoverPassword = React.lazy(() => import("../modules/confirm-recover-password/ConfirmRecoverPassword"))
const NoMatch = React.lazy(() => import("../modules/no-match/NoMatch"))
const Forbidden = React.lazy(() => import("../modules/forbidden/Forbidden"))
const Advertisements = React.lazy(() => import("../modules/advertisements/Advertisements"))
const Formats = React.lazy(() => import("../modules/formats/Formats"))
const AdvertisementDetail = React.lazy(() => import("../modules/advertisement-detail/AdvertisementDetail"))
const EditLayout = React.lazy(() => import("../modules/edit-layout/EditLayout"))

const routes = [
		{
				path: Paths.LOGIN,
				component: Login
		}, {
				path: Paths.LOGOUT,
				component: Logout
		}, {
				path: Paths.RECOVER_PASSWORD,
				component: RecoverPassword
		}, {
				path: Paths.CONFIRM_RECOVER_PASSWORD,
				component: ConfirmRecoverPassword
		}, {
    path: Paths.NO_MATCH,
    component: NoMatch
  }, {
    path: Paths.FORBIDDEN,
    component: Forbidden
  }, {
    path: Paths.ADVERTISEMENT_EDIT_LAYOUT,
    component: EditLayout
  }, {
    path: Paths.ADVERTISEMENT_DETAILS,
    component: AdvertisementDetail
  }, {
    path: Paths.ADVERTISEMENTS,
    component: Advertisements
  }, {
    path: Paths.FORMATS,
    component: Formats
  }, {
				path: Paths.HOME,
				component: Home
		}
]

const Routes = () => {
		const componentsRoutes: any[] = []

		const renderRoutes = (routes: any[], path: string) => {
				routes.forEach((route: any) => {
						if (route.routes) {
								renderRoutes(route.routes, route.path)
						}

						const routePath = (path + route.path).replace('//', '/')
						componentsRoutes.push(
								<ErrorBoundaryRoute
										key={componentsRoutes.length}
										path={routePath}
										component={route.component}
								/>
						)
				})
		}

		renderRoutes(routes, '')

		return (
				<Router>
						<Suspense fallback={<Loading/>}>
								<Switch>{componentsRoutes}</Switch>
						</Suspense>
				</Router>
		)
}

export default Routes
