import Axios from 'axios'
import {Config} from '../config'
import {StateType} from "../types/StateType";
import Store from "../redux/store/Store";
import Paths from "../config/Paths";

const HttpClient = {
  getHeaders: (login: boolean) => {
    const headers: any = {
      'Access-Control-Allow-Origin': '*',
    }

    if (login) {
      const state: StateType = Store.getState()
      headers.Authorization = 'Bearer ' + state.authentication.token
    }

    return headers
  },

  getData: (data: any) => {
    return data
  },

  validateStatus: (status: number) => {
    switch (status) {
      case 401:
        window.location.href = Config.BASE_URL + Paths.LOGOUT
        break
      case 403:
        window.location.href = Config.BASE_URL + Paths.FORBIDDEN
        break
      case 404:
        window.location.href = Config.BASE_URL + Paths.NO_MATCH
        break
    }

    return status >= 200 && status < 300
  },

  redirectBase: (): void => {
    window.location.href = Config.BASE_URL
  },

  get: (
    pathRelative: string,
    data: any,
    success?: any,
    error?: any,
    always?: any,
    login = true
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios.get(Config.END_POINT + pathRelative, {
      params: HttpClient.getData(data),
      cancelToken: source.token,
      headers: HttpClient.getHeaders(login),
      validateStatus: (status) => {
        return HttpClient.validateStatus(status)
      },
    })
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (errors.response && error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  },

  getExternal: (
    path: string,
    data: any,
    success?: any,
    error?: any,
    always?: any,
    login = true
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios.get(path, {
      params: HttpClient.getData(data),
      cancelToken: source.token,
      headers: HttpClient.getHeaders(login),
      validateStatus: (status) => {
        return HttpClient.validateStatus(status)
      },
    })
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (errors.response && error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  },

  postLogin: (
    path: string,
    params: any,
    success?: any,
    error?: any,
    always?: any
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      }
    }

    Axios.post(path, params, config)
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  },

  post: (
    pathRelative: string,
    data: any,
    success?: any,
    error?: any,
    always?: any,
    login = true
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios.interceptors.request.use((config: any) => {
      if (config.data instanceof FormData) {
        config.headers = {
          ...config.headers,
          'Content-Type': 'multipart/form-data',
        }
      }

      return config
    })

    Axios({
      method: 'post',
      cancelToken: source.token,
      url: pathRelative,
      data: HttpClient.getData(data),
      baseURL: Config.END_POINT,
      headers: HttpClient.getHeaders(login),
      validateStatus: (status) => {
        return HttpClient.validateStatus(status)
      },
    })
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  },

  put: (
    pathRelative: string,
    data: any,
    success?: any,
    error?: any,
    always?: any,
    login = true
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios({
      method: 'put',
      cancelToken: source.token,
      url: pathRelative,
      data: HttpClient.getData(data),
      baseURL: Config.END_POINT,
      headers: HttpClient.getHeaders(login),
      validateStatus: (status) => {
        return HttpClient.validateStatus(status)
      },
    })
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  },

  delete: (
    pathRelative: string,
    data: any,
    success?: any,
    error?: any,
    always?: any,
    login = true
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios({
      method: 'delete',
      cancelToken: source.token,
      url: pathRelative,
      data: HttpClient.getData(data),
      baseURL: Config.END_POINT,
      headers: HttpClient.getHeaders(login),
      validateStatus: (status) => {
        return HttpClient.validateStatus(status)
      },
    })
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  },
  getBlob: (
    path: string,
    data: any,
    success?: any,
    error?: any,
    always?: any,
    login = true
  ) => {
    const CancelToken = Axios.CancelToken
    const source = CancelToken.source()

    Axios.get(Config.END_POINT + path, {
      responseType: 'blob',
      params: HttpClient.getData(data),
      cancelToken: source.token,
      headers: HttpClient.getHeaders(login),
      validateStatus: (status) => {
        return HttpClient.validateStatus(status)
      },
    })
      .then(function (response) {
        if (success) {
          success(response)
        }
      })
      .catch(function (errors) {
        console.log(errors)
        if (error) {
          error(errors)
        }
      })
      .then(function () {
        if (always) {
          always()
        }
      })

    return source
  }
}
export default HttpClient
