import ActionType from "../../types/ActionType";
import MenusType from "../../types/MenusType";
import {MENU_SET_DATA, MENU_SPINNER} from "../constants/menu";

const initialState: MenusType = {
  spinner: false,
  loaded: false,
  menus: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: MenusType = initialState, action: ActionType): MenusType => {
  switch (action.type) {
    case MENU_SPINNER:
      return {
        ...state,
        spinner: action.payload.spinner
      }
    case MENU_SET_DATA:
      return {
        ...action.payload
      }
    default:
      return state
  }
}
