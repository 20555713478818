const Paths = {
  LOGIN: '/login',
		RECOVER_PASSWORD: '/recover-password',
		CONFIRM_RECOVER_PASSWORD: '/confirm-recover-password/:token',
  HOME: '/',
		LOGOUT: '/logout',
		NO_MATCH: '/no-match',
		FORBIDDEN: '/forbidden',
		ADVERTISEMENTS: '/advertisements',
		ADVERTISEMENT_DETAILS: '/advertisements/:id',
		ADVERTISEMENT_EDIT_LAYOUT: '/advertisements/:id/edit/layout/:templateId',
		FORMATS: '/formats',
}

export default Paths
