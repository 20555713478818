export const Endpoints = {
  POST_LOGIN: 'login',
  GET_FORGOT_PASSWORD: 'forgot-password',
  GET_FORGOT_PASSWORD_USER_INFO: 'tokens/:token',
  POST_RESET_PASSWORD: 'reset-password',

  GET_USERS_TYPES: 'users/types',
  POST_USER: 'users',
  GET_USERS: 'users',
  DELETE_USER: 'users/:id',
  GET_USER: 'users/:id',
  PUT_USER: 'users/:id',

  GET_NOTIFICATIONS: 'notifications',
  PUT_NOTIFICATIONS_READED: 'notifications/:id/readed',
  PUT_NOTIFICATIONS_READ_ALL: 'notifications/read-all',

  GET_MENUS: 'menus',

  GET_TEMPLATES: 'templates',
  POST_TEMPLATES: 'templates',
  GET_TEMPLATE: 'templates/:id',
  DELETE_TEMPLATE: 'templates/:id',
  PUT_TEMPLATE: 'templates/:id',

  GET_ADVERTISEMENTS: 'advertisements',
  POST_ADVERTISEMENTS: 'advertisements',
  GET_ADVERTISEMENT: 'advertisements/:id',
  PUT_ADVERTISEMENT: 'advertisements/:id',
  GET_ADVERTISEMENTS_TEMPLATES: 'advertisements/:id/templates',
  DELETE_ADVERTISEMENTS: 'advertisements/:id',
  DELETE_ADVERTISEMENTS_FORMAT: 'advertisements/:id/format/:format',

  GET_FORMATS: 'formats',
  POST_FORMATS: 'formats',
  DELETE_FORMATS: 'formats/:id',
  PUT_FORMATS: 'formats/:id',

  //TDT_COMMONS
  GET_ACCOUNTS_ALL: 'accounts/all',
  GET_ACCOUNTS: 'accounts',
}
