import AuthenticationType from "../../types/AuthenticationType";
import {AUTHENTICATION_SET_DATA, AUTHENTICATION_SET_TOKEN} from "../constants/authentication";
import SessionStorage from '../../commons/SessionStorage'
import LocalStorage from '../../commons/LocalStorage'
import moment from "moment";
import {Config} from "../../config";
import {Functions} from "../../commons/Functions";
import qs from "qs";
import Axios from "axios";

let intervalRefresToken: any = null

export function refreshToken() {
  return (dispatch: any) => {
    if (isExpiredToken()) {
      const params = qs.stringify({
        'client_id': Config.LOGIN_CLIENT_ID,
        'refresh_token': getRefreshToken(),
        'grant_type': 'refresh_token',
      })

      Axios.post(
        Config.END_POINT_LOGIN,
        params,
        {
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          }
        })
        .then(function (response) {
          const token = response.data.access_token
          const expires_in = response.data.expires_in
          const refresh_expires_in = response.data.refresh_expires_in
          const refresh_token = response.data.refresh_token

          setSessionRefreshToken({
            name: '',
            token,
            url: '',
            expires_in,
            refresh_expires_in,
            refresh_token,
          })

          dispatch(authenticationSetToken(token))

          if (intervalRefresToken !== null) {
            clearInterval(intervalRefresToken)
          }

          intervalRefresToken = setInterval(() => dispatch(refreshToken()), 1000 * 60)
        })
        .catch(function (errors) {
          Functions.openNotificationError('Ha ocurrido un error al actualizar el token de seguridad.')
        })
    }
  }
}

export const authenticationSetData = (data: AuthenticationType) => {
  return {
    type: AUTHENTICATION_SET_DATA,
    payload: {...data},
  }
}

export const authenticationSetToken = (token: string) => {
  return {
    type: AUTHENTICATION_SET_TOKEN,
    payload: {token},
  }
}

type Data = {
  name: string
  token: string
  url: string
  expires_in: string
  refresh_expires_in: string
  refresh_token: string
}

export const setSession = (data: Data, rememberMe: boolean) => {

  if (rememberMe) {
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_name', data.name)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_token', data.token)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_url', data.url ? data.url : '')
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in', data.expires_in)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_expires_in', data.refresh_expires_in)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_token', data.refresh_token)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in_date', moment().add(data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss'))
  } else {
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_name', data.name)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_token', data.token)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_url', data.url ? data.url : '')
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in', data.expires_in)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_expires_in', data.refresh_expires_in)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_token', data.refresh_token)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in_date', moment().add(data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss'))
  }
}

const setSessionRefreshToken = (data: Data) => {
  let token = SessionStorage.getItem(Config.LOGIN_CLIENT_ID + '_user_token')
  if (token) {
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_token', data.token)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in', data.expires_in)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_expires_in', data.refresh_expires_in)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_token', data.refresh_token)
    SessionStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in_date', moment().add(data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss'))
  } else {
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_user_token', data.token)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in', data.expires_in)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_expires_in', data.refresh_expires_in)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_refresh_token', data.refresh_token)
    LocalStorage.setItem(Config.LOGIN_CLIENT_ID + '_expires_in_date', moment().add(data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss'))
  }
}

const isExpiredToken = () => {
  let dateExpiredToken = SessionStorage.getItem(Config.LOGIN_CLIENT_ID + '_expires_in_date')
  if (dateExpiredToken) {
    if (moment().isBefore(moment(dateExpiredToken))) {
      return false
    }
  }

  dateExpiredToken = LocalStorage.getItem(Config.LOGIN_CLIENT_ID + '_expires_in_date')
  if (dateExpiredToken) {
    if (moment().isBefore(moment(dateExpiredToken))) {
      return false
    }
  }

  return true
}

const getRefreshToken = () => {
  let refreshToken = SessionStorage.getItem(Config.LOGIN_CLIENT_ID + '_refresh_token')
  if (refreshToken) return refreshToken

  refreshToken = LocalStorage.getItem(Config.LOGIN_CLIENT_ID + '_refresh_token')
  if (refreshToken) return refreshToken

  return null
}


function buildSessionData(storage: any) {
  let name = storage.getItem(Config.LOGIN_CLIENT_ID + '_user_name')
  let access_token = storage.getItem(Config.LOGIN_CLIENT_ID + '_user_token')
  let avatar = storage.getItem(Config.LOGIN_CLIENT_ID + '_user_url')

  if (name && access_token) {
    return {
      isAuthenticated: true,
      account: {
        name: name,
        avatar
      },
      token: access_token,
    }
  }

  return null
}

export const getSession = () => {
  let sessionData = buildSessionData(SessionStorage)
  if (sessionData) return sessionData

  sessionData = buildSessionData(LocalStorage)
  if (sessionData) return sessionData

  return {
    isAuthenticated: false,
    account: {
      name: '',
      avatar: ''
    },
    token: '',
  }
}

export const clearSession = () => {
  LocalStorage.clearAll()
  SessionStorage.clearAll()
}
